.Attachments-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  &-ImageInfo {
    font-size: 1rem;
    color: var(--warning);
  }

  @media (max-width: 1020px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

.Attachments-Header-Group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;

  @media (max-width: 1020px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.Terms-Container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.Remove-Button {
  color: var(--link);
  font-weight: 500;
  text-decoration: underline;
}

.PartSearch {
  &-input-container {
    display: flex;
    align-items: center;
    gap: 10px;

    padding: 12px 15px;
  }

  &-input {
    background: #fcb026 !important;
    color: black;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 4px;
    transition: background-color 0.3s, border-color 0.3s, opacity 0.3s; /* Smooth transitions */

    &::placeholder {
      color: var(--black);
    }

    &:hover {
      background: var(--jcb-orange);
      border-color: #ffa500;
    }

    &:focus,
    &:hover {
      background-color: none;
    }
  }

  &-list {
    border-top: 1px solid #e39e22;
  }

  &-item {
    padding: 7.5px 10px;

    &.is-focused {
      background-color: #e39e22;
    }
  }
}

.part-search .part-search-input::placeholder {
  color: black !important;
  opacity: 1;
}

.Menu-Item {
  position: relative;
  padding: 12px 15px;

  text-transform: capitalize;

  cursor: pointer;

  &:hover {
    background-color: var(--hover-orange);
  }

  &-Border {
    border-bottom: 1px solid var(--border-orange);
  }

  &-Link {
    color: var(--black);
    text-transform: capitalize;

    &:hover {
      color: var(--black);
      text-decoration: none !important;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 0;
    }
  }

  &-Selected {
    background-color: var(--jcb-orange);
    font-weight: 700;
  }

  &-Flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

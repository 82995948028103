.info-dialog {
  .MuiPaper-root {
    overflow-y: hidden;
  }
}

.fitment-dialog {
  min-width: 500px;

  @media (max-width: 680px) {
    min-width: 0;
  }

  &-content {
    overflow-y: auto;
    min-height: 100px;
    max-height: 500px;
  }

  &-bar {
    position: sticky;
    top: 0;
    background-color: var(--white);
    padding: 10px 24px;
  }

  .header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 6px 8px;
    background-color: #ededed;
  }
}

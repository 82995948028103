.Term {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 25px;
  min-width: 150px;
  color: var(--white);
  background-color: var(--lozenge-background);
  border-radius: 999px;
  padding: 4px 0 4px 8px;

  &:hover > .Term-Button {
    background-color: var(--white);
    color: var(--lozenge-background);
  }
}

.Term-Text {
  font-size: 12px;
  text-transform: capitalize;

  &-Bold {
    font-weight: 700;
  }
}

.Term-Button {
  display: flex;
  align-items: center;
  height: 23px;
  width: 23px;
  padding: 5px;
  justify-content: center;
  margin-left: 1px;
  transition: 200ms;
  border-radius: 999px;
}

.Filters {
  padding: 0;
  list-style: none;
  border-bottom-width: 0;

  @media (max-width: 1020px) {
    width: 100%;
  }
}

.Filters-Header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}

.Filters-Header-Title {
  font-size: 14px;
  font-weight: 700;
  color: var(--border-primary);
}

.Filters-Container {
  border: 1px solid var(--border-secondary);
}

.App {
  --white: #ffffff;
  --black: #000000;
  --link: #3366bb;
  --jcb-orange: #f4a824;
  --border-orange: #e39e22;
  --border-primary: #5a5951;
  --border-secondary: #e0e0e0;
  --hover-orange: #f4a61f;
  --sidebar: #fcb026;
  --background-gray: #ededed;
  --box-shadow: rgba(0, 0, 0, 0.15);
  --icon: #3d3d39;
  --text-secondary: #b0b0b0;
  --lozenge-background: #424242;
  --warning: #db3814;
  
  .wrapper {
    display: flex;
  }

  .Skeleton-Container {
    background-color: var(--white);
  }

  .Linear-Progress {
    background-color: var(--jcb-orange);

    .MuiLinearProgress-bar {
      background-color: var(--border-orange);
    }
  }
}

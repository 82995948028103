.Machines {
  $grid-item-width: 200px;

  display: grid;
  gap: 30px 20px;
  grid-template-columns: repeat(auto-fit, $grid-item-width);
  justify-items: flex-start;
  min-width: auto;
  margin-top: -55px;

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, $grid-item-width);
    justify-content: center;
  }
}

.Table-Picture {
    width: 100px;
    min-height: 38px;
    max-height: 73px;
    margin: 12px 0;
  
    border: 1px solid rgba(0,0,0,.1);
  
    object-fit: contain;

    cursor: pointer;
  
    &-Error {
        padding: 20px 30px;
    }

    &-Placeholder {
        cursor: default;
    }
}

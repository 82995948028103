.Accordion-Header {
  display: flex;
  justify-content: space-between;

  &-Opened {
    background: var(--jcb-orange);
  }
}

.Accordion-Header-Button {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  z-index: 9999;
}

.Accordion-Header-Button-Opened {
  border-bottom: 1px solid var(--border-orange);
}

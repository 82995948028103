.Table {
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      padding: 8px;

      text-align: left;
      white-space: nowrap;
    }
  }

  tbody {
    tr:not(:last-child) {
      border-bottom: 1px solid var(--border-secondary);
    }

    td {
      padding: 8px;
    }
  }

  .Table-Sort-Icon {
    margin-left: 6px;
  }
}

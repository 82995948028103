.Model-Selection {
  .Model-Input {
    margin: 0;
    margin-top: 12px;
    width: 250px;

    .MuiInputBase-input {
      padding-left: 0;
    }
  }

  .Model-Selection-Wrapper {
    padding: 20px;

    .Model-Title {
      margin-bottom: 15px;
      font-weight: 700;
    }

    .Model-Item {
      display: block;

      &:hover {
        color: var(--link);
      }

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      @media (max-width: 1020px) {
        margin-bottom: 10px;
      }
    }

    .Models {
      display: grid;
      grid-template-columns: repeat(3, 250px);
      grid-auto-flow: row dense;
      gap: 20px;

      @media (max-width: 1020px) {
        grid-template-columns: 250px;
        gap: 0;
      }
    }
  }
}

$colour-accent: #fcb026;
$colour-accent-active: #f4a824;
$colour-accent-hover: darken($colour-accent-active, 1);
$colour-accent-selected: $colour-accent-active;
$app-background: white;
$title-link-colour: black;
$title-link-colour-hover: lighten($title-link-colour, 30);
$link-colour: #3366bb;
$link-colour-hover: lighten($link-colour, 10);
$button-text-colour: #212529;
$body-font-family: 'Roboto', 'Segoe', 'Candara', 'Calibri', 'Arial', sans-serif;
$heading-font-family: 'Roboto', 'Segoe', 'Candara', 'Calibri', 'Arial', sans-serif;
$heading-text-colour: black;

@use '~@cognitranlimited/itis-web/dist/styles' with (
    $colour-accent: $colour-accent,
    $app-background: $app-background,
    $body-background: $app-background,
    $body-font-family: $body-font-family,
    $Link-text-colour: $link-colour,
    $Link-text-colour-hover: $link-colour-hover,
    $Link-title-text-colour: $title-link-colour,
    $Link-title-text-colour-hover: $title-link-colour-hover,
    $heading-text-colour: $heading-text-colour,
    $heading-font-family: $heading-font-family,
    $Button-text-colour: $button-text-colour,
    $Button-text-colour-disabled: rgba(0, 0, 0, 0.26),
    $Button-background-disabled: rgba(0, 0, 0, 0.12),
    $Button-background: $colour-accent,
    $Button-text-colour-danger: white,
    $Button-text-colour-outlined: $button-text-colour,
    $Button-border-outlined: 1px solid $button-text-colour,
    $Button-text-colour-outlined-hover: lighten($button-text-colour, 20),
    $Button-border-colour-outlined-hover: lighten($button-text-colour, 20),
    $Button-text-colour-outlined-active: $button-text-colour,
    $Button-border-colour-outlined-active: $button-text-colour,
    $Sidebar-text-colour: $heading-text-colour,
    $Sidebar-background: $colour-accent,
    $SidebarSectionItem-background-hover: $colour-accent-hover,
    $SidebarSectionItem-background-active: $colour-accent-active,
    $SidebarSectionItem-background-selected: $colour-accent-selected,
    $SidebarSection-background: $colour-accent,
    $Sidebar-light-text-colour: $title-link-colour,
    $Panel-header-text-transform: uppercase,
    $Panel-header-font-weight: 600,
    $Panel-text-colour: black,
    $ContentHeader-title-font-weight: 400,
    $VehicleHistoryPopover-header-font-weight: 500,
    $LanguageSelectItem-selected-text-colour: $title-link-colour,
    $NewLookPanel-background: #fff5e4,
    $ContentHeader-title-text-transform: uppercase,
    $Header-icon-colour: $title-link-colour,
    $IconButton-icon-colour: $title-link-colour,
    $IconButton-icon-colour-disabled: lighten($title-link-colour, 80),
    $NoticeItem-summary-text-colour: #5a5951,
    $MessageTableRow-read-text-colour: #5a5951,
    $MessageTableRow-read-background: #fafafa,
    $notification-text-colour: white
);

@use '~@cognitranlimited/itis-web/dist/styles/campaigns';

@use '@fontsource/roboto/scss/mixins' as Roboto;
@include Roboto.fontFace();

/**
 * Temporary overrides (these should have a corresponding core case to avoid accumulating styling overrides)
**/

.Dialogue-header h2 {
    text-transform: uppercase;
}

:not(.VehicleHistoryButtons) > {
    .Button,
    a.ButtonLink {
        &,
        &:hover {
            font-size: 14px;
            padding: 12px 26px;
            font-family: $heading-font-family;
            font-weight: 500;
            font-stretch: condensed;
        }
    }
}

.Expander {
    &-expand {
        color: $title-link-colour;
    }

    &-header {
        &:hover .Expander-expand {
            color: $title-link-colour-hover;
        }

        &:active .Expander-expand {
            color: $title-link-colour;
        }
    }
}

.ContentHeader-back {
    &,
    &:hover,
    &:active {
        color: $title-link-colour;
    }
}

.NoticeItem h4 button {
    font-weight: 600;
}

.SubscriptionsTableRow .TableActionCell .ButtonLink {
    &,
    &:hover {
        padding: 8px 12px;
    }
}

.MuiTab-textColorPrimary {
    &:hover {
        color: $heading-text-colour;
    }

    &.Mui-selected {
        color: $heading-text-colour !important;
        font-weight: bold;
    }
}

.Breadcrumbs {
  padding: 30px 20px;
  background-color: var(--background-gray);

  z-index: -1;

  &.isExpanded {
    height: 125px;
  }
}

.Breadcrumb {
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    margin: 0;
  }
}

.Breadcrumb-Text {
  color: var(--black);

  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;

  @media (max-width: 680px) {
    font-size: 16px;
  }
}

.Breadcrumb-Link {
  color: var(--link);

  transition: 200ms;

  &:hover {
    text-decoration: underline;

    color: var(--black);
  }
}

.Breadcrumb-Separator {
  margin: 0 10px;
}

.Breadcrumb-Vehicle-Info {
  padding-bottom: 2px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
}

.ExpandedSidebar_Logo {
  width: 200px;
  margin: 25px;
  padding: 0;
}

.ExpandedSidebar_Logo_Wrapper {
  width: 100%;
  background-color: var(--sidebar);
  position: sticky;
  top: 0;
  z-index: 1001;
}

.SidebarSection-Small {
  width: 130px;
}

.Menu-Item-Center {
  text-align: center;
}

.Menu-Item-With-Icon {
  display: flex;
  align-items: center;
  gap: 10px;

  .Menu-Item-Icon {
    color: var(--icon);
  }
}

.SidebarUserSection {
  display: flex;
  flex-direction: column;
  padding: 12px 15px;
  gap: 8px;

  .Sidebar_LogoutButton {
    text-transform: capitalize;
    align-self: flex-start;
  }
}

.partOverview {
  overflow-y: auto;
  $breadcrumb-height: 85px;

  .Breadcrumbs {
    height: $breadcrumb-height;
  }

  &-card {
    padding: 20px;

    &.is-empty-response {
      display: flex;
      height: calc(100% - $breadcrumb-height);
      align-items: center;
      justify-content: center;
    }
  }

  &-header {
    display: flex;
    gap: 30px;
    align-items: flex-start;
    margin-bottom: 16px;

    &-title.MuiCardHeader-root {
      padding: 0;
    }

    @media (max-width: 1020px) {
      gap: 15px;
      flex-direction: column;
    }
  }

  &-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  
    .MuiCircularProgress-root {
      color: var(--jcb-orange);
    }
  }

  &-content {
    margin-top: 16px;
  }

  &-image {
    max-width: 100%;
    max-height: 100%;
    height: 300px;
    object-fit: contain;
    object-position: center;
  }
}

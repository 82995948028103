.MachineBox {
  display: flex;
  flex-direction: column;
  width: 190px;
  min-height: 200px;
  box-shadow: 0px 0px 5px var(--box-shadow);
  background-color: var(--white);

  @media (max-width: 780px) {
    height: unset;
    align-items: center;
    justify-content: center;
  }

  &-Image-Container {
    flex: 1;
    width: 100%;
  }

  &-Image {
    max-width: 100%;
    max-height: 100%;
    height: 140px;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }

  &-Title {
    font-size: 16px;
    font-weight: 700;
  }

  &-Icon {
    color: var(--black);
  }

  &-Info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 60px;
    padding: 10px;
    border-top: 1px solid var(--box-shadow);
  }
}

.Search {
  position: relative;

  display: flex;
  align-items: stretch;

  width: 250px;

  @media (max-width: 1020px) {
    width: 100%;
  }

  .Search-Button {
    position: absolute;
    top: 50%;
    left: 8px;

    transform: translateY(-50%);
  }
}

.Search .Search-Input {
  padding-left: 30px;
  background: var(--background-gray);
  border: none;

  &::placeholder {
    text-transform: capitalize;
  }
}

.Search-Icon {
  width: 15px;
}

.Attachments {
  padding: 20px;

  .Attachments-Wrapper {
    display: flex;

    align-items: flex-start;
    gap: 30px;

    margin-top: 24px;

    @media (max-width: 1020px) {
      display: block;
      max-width: 100%;
      overflow-x: auto;
    }
  }

  .attachments-actions {
    flex-basis: 250px;
  }

  .SearchWrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    &-ImageInfo {
      font-size: 1rem;
      color: var(--warning);
    }
  }
}

.ChangeModel {
  &.Screen {
    overflow: visible;
  }

  .Overlay-Wrapper {
    margin-top: -55px;
  }

  .PanelGrid {
    padding: 0;
  }

  .Panel {
    overflow-y: visible;
  }

  .ChangeModel-Panel {
    z-index: 999;

    .ChangeModel-Title {
      padding-left: 20px;
      padding-top: 20px;
      font-size: 11px;
      font-weight: 700;
    }

    .ChangeModel-Buttons {
      display: flex;
      gap: 15px;

      @media (max-width: 1020px) {
        flex-direction: column;
        justify-content: center;
      }
    }

    .ChangeModel-LinkIcon {
      margin-left: 5px;
    }

    .Panel-header {
      padding-top: 0;
    }
  }
}

.Accordion-Button {
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;
  padding: 12px 15px;

  font-weight: 700;

  text-transform: uppercase;

  &.disabled {
    cursor: default;
    color: rgba(0, 0, 0, 0.26);
  }
}

.Accordion-Content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 12px 15px;
}

.Chevron {
  width: 20px;

  &.rotated {
    transform: rotate(270deg);
  }
}

.checkbox {
  width: auto !important;
}

.checkbox-label {
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
}

.Checkbox-Flex {
  display: flex;
  align-items: center;
}

.Filter-Count {
  color: var(--text-secondary);
}

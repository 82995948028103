.Menu-List {
  margin-top: 12px;
  padding: 0;
  min-height: 41px;

  list-style: none;

  li:not(:first-child) {
    border-top: 1px solid var(--border-orange);
  }
}

.Categories-List-Outer {
  height: 100%;

  padding: 0 20px;
  margin-top: -55px;

  @media (max-width: 680px) {
    margin-top: -35px;
  }
}

.Categories-List-Inner {
  $grid-item-width: 200px;

  display: grid;
  grid-template-columns: repeat(auto-fit, $grid-item-width);
  justify-items: flex-start;
  gap: 30px 20px;
  padding-bottom: 16px;

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, $grid-item-width);
    justify-content: center;
  }
}

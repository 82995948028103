.Attachments-Table {
  display: block;
  max-width: 1000px;
  min-height: 250px;
  overflow-x: hidden;
  width: 100%;
  margin-top: 29px;
  flex: 1;

  @media (max-width: 1020px) {
    margin-top: 16px;
  }

  &-Overflow {
    overflow-x: auto;
  }
}

.Attachments-Table-No-Result {
  height: 250px;
  
  display: flex;
  align-items: center;
  justify-content: center;
}
